<template>
  <div class="system-view">
    <el-row :gutter="25">
      <el-col :span="12">
        <el-card>
          <div slot="header">
            <span>驾驶员必备条件设置</span>
          </div>
          <el-row>
            <el-switch v-model="isDriverAge" @change="changeSetting($event, 'isDriverAge')"></el-switch> 驾驶员年龄大于等于
            <el-input size="mini" type="number" v-model="driverAgeMin" @blur="changeAgeSetting(driverAgeMin, 'driverAgeMin')"></el-input> 小于等于
            <el-input size="mini" type="number" v-model="driverAgeMax" @blur="changeAgeSetting(driverAgeMax, 'driverAgeMax')"></el-input>
          </el-row>
          <div class="train-title">培训经历(新增或者删除培训经历请点击保存按钮)&nbsp;&nbsp;<i class="el-icon-circle-plus-outline op-icon" @click="addTrain('driverTrainList')"></i></div>
          <div :key="'training_' + i" v-for="(t, i) in driverTrainList">
            <el-row>
              <span :key="'label_' + i">参加过</span>
              <el-select v-model="driverTrainList[i]" size="mini" :key="'value_' + i">
                <el-option v-for="s in trainList" :key="s.dictId" :label="s.dictName" :value="s.dictId"></el-option>
              </el-select>
              <i :key="'icon_' + i" class="op-icon el-icon-delete-solid" @click="removeTrain(i, 'driverTrainList')" v-if="driverTrainList.length > 0"></i>
            </el-row>
          </div>
          <el-button type="primary" @click="saveDriverTrainList()">保存</el-button>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div slot="header">
            <span>操作员必备条件设置</span>
          </div>
          <el-row>
            <el-switch v-model="isOperatorAge" @change="changeSetting($event, 'isOperatorAge')"></el-switch> 操作员年龄大于等于
            <el-input size="mini" type="number" v-model="operatorAgeMin" @blur="changeAgeSetting(operatorAgeMin, 'operatorAgeMin')"></el-input> 小于等于
            <el-input size="mini" type="number" v-model="operatorAgeMax" @blur="changeAgeSetting(operatorAgeMax, 'operatorAgeMax')"></el-input>
          </el-row>
          <div class="operator-title">培训经历(新增或者删除培训经历请点击保存按钮)&nbsp;&nbsp;<i class="el-icon-circle-plus-outline op-icon" @click="addTrain('operatorTrainList')"></i></div>
          <div :key="'otraining_' + i" v-for="(t, i) in operatorTrainList">
            <el-row>
              <span :key="'olabel_' + i">参加过</span>
              <el-select v-model="operatorTrainList[i]" size="mini" :key="'value_' + i">
                <el-option v-for="s in trainList" :key="s.dictId" :label="s.dictName" :value="s.dictId"></el-option>
              </el-select>
              <i :key="'oicon_' + i" class="op-icon el-icon-delete-solid" @click="removeTrain(i, 'operatorTrainList')" v-if="operatorTrainList.length > 0"></i>
            </el-row>
          </div>
          <el-button type="primary" @click="saveOperatorTrainList()">保存</el-button>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'system-person',
  data () {
    return {
      isDriverAge: true,
      isOperatorAge: true,
      driverAgeMin: 20,
      driverAgeMax: 55,
      operatorAgeMin: 20,
      operatorAgeMax: 55,
      driverTrainList: [],
      operatorTrainList: [],
      trainList: []
    }
  },
  async created () {
    let data = await this.$http({
      url: this.$http.adornUrl('/user/train/dict/list'),
      method: 'post'
    })
    this.trainList = data.datas
    data = await this.$http({
      url: this.$http.adornUrl('/rule/user/get'),
      method: 'post'
    })
    for (const key in data.datas) {
      if (data.datas.hasOwnProperty(key)) {
        const v = data.datas[key]
        // eslint-disable-next-line eqeqeq
        if (key != 'driverTrainList' && key != 'operatorTrainList') {
          this[key] = v
        }
      }
    }
    console.log(data.datas)
    for (let i = 0; i < data.datas.driverTrainList.length; i++) {
      this.driverTrainList.push(data.datas.driverTrainList[i].trainId)
    }
    for (let j = 0; j < data.datas.operatorTrainList.length; j++) {
      this.operatorTrainList.push(data.datas.operatorTrainList[j].trainId)
    }
  },
  methods: {
    addTrain (prop) {
      this[prop].push('')
      console.log(this[prop])
    },
    removeTrain (i, prop) {
      this[prop].splice(i, 1)
      console.log(this[prop])
    },
    async changeSetting (value, prop) {
      await this.$http({
        url: this.$http.adornUrl('/rule/user/save'),
        method: 'post',
        data: {
          [prop]: value
        }
      }).then(data => {
        if (data.code === 200) {
          this.$message.success('修改成功')
        }
      })
    },
    async changeAgeSetting (value, prop) {
      await this.$http({
        url: this.$http.adornUrl('/rule/user/save'),
        method: 'post',
        data: {
          [prop]: value
        }
      }).then(data => {
        if (data.code === 200) {
          this.$message.success('修改成功')
        }
      })
    },
    saveDriverTrainList () {
      this.$http({
        url: this.$http.adornUrl('/rule/user/carDriverTrain/save'),
        method: 'post',
        data: { post: 1, trainIdList: this.driverTrainList }
      }).then(data => {
        if (data.code === 200) {
          this.$message.success('保存成功')
        }
      })
    },
    saveOperatorTrainList () {
      this.$http({
        url: this.$http.adornUrl('/rule/user/carDriverTrain/save'),
        method: 'post',
        data: { post: 2, trainIdList: this.operatorTrainList }
      }).then(data => {
        if (data.code === 200) {
          this.$message.success('保存成功')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.train-title {
  margin-bottom: 10px;
  .op-icon {
    margin-left: 10px;
    color: #666;
    cursor: pointer;
    transition: color 0.2s;
    &:hover {
      color: #999;
    }
  }
}
</style>
